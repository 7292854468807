'use client';

import { useContext, useState, useMemo, useCallback } from 'react';
import cn from 'classnames';

import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Input from '@guestyci/foundation/Input';
import Accordion from '@guestyci/foundation/Accordion';
import DropdownMenu from '@guestyci/foundation-legacy/baseDropdown/DropdownMenu';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { getSymbolFromCurrency, prettifyEnumText } from 'utils';
import useIsMobile from 'hooks/useIsMobile';
import currencySymbolMap from 'constants/currencies';
import { CurrencyContext } from 'context/CurrencyContext';
import { SearchValuesContext } from 'context/SearchValuesContext';
import Icon from 'components/Icon';
import { PRICE_FILTER_FIX } from 'constants/featureToggleNames';
import { getItemTranslation, useConfigSettings } from 'hooks/useConfigSettings';
import useTotalPrice from 'hooks/useTotalPrice';
import { SEARCH_VALUES_ACTIONS } from 'hooks/useSearchValues';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [create('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: 0,
    },
    [create('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    cursor: 'pointer',
  },
  priceInput: {
    display: 'flex',
  },
  dropdownRoot: {
    width: 'initial',
    marginRight: 23,
    cursor: 'pointer',
    [create('xs')]: {
      padding: 15,
      width: '100%',
    },
    [create('md')]: {
      width: 'initial',
      maxWidth: 300,
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  block: {
    display: 'flex',
    [create('xs')]: {
      borderLeft: 'none',
      padding: 0,
    },
    [create('lg')]: {
      borderLeft: '1px solid #77838D',
      padding: '0 15px',
    },
  },
  total: {
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
  accordion: {
    paddingBottom: 0,
    '& button': {
      padding: '0 15px',
    },
  },
  clearAll: {
    cursor: 'pointer',
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
  dropdownOption: {
    width: 300,
  },
}));

const SearchFilters = ({ totalListings }) => {
  const {
    allPropertyTypes: propertyTypes,
    allAmenityTypes: amenityTypes,
    newPropertyTypes,
    newAmenityTypes,
    allHouseRules,
  } = useConfigSettings();

  const {
    contentConfiguration: { searchSettings } = {},
    contentConfiguration: { searchSettings: { filterByTags } = {} } = {},
  } = useContext(WebsiteSettingsContext);

  const categoriesFilterName = useMemo(() => {
    return filterByTags?.customName ? `${filterByTags.customName}:` : t('Categories:');
  }, [filterByTags]);

  const { isTotalPriceEnabled } = useTotalPrice();

  const {
    enableBathroomsFilter: { value: maxBathrooms = 10, active: activeBathroom = true } = {},
    enableBedroomsFilter: { value: maxBedrooms = 10, active: activeBedroom = true } = {},
    filterByTags: { value: tagsData, translations: tagsTranslations, active: activeTags = true } = {},
    filterByAmenities: { value: amenitiesData, active: activeAmenities = true } = {},
    filterByPropertyType: { value: propertyData, active: activePropertyType = true } = {},
  } = searchSettings || {};

  const tagsTranslationsMapper = useMemo(() => {
    return tagsTranslations?.reduce((acc, el) => {
      acc[el.id] = el.label;
      return acc;
    }, {});
  }, [tagsTranslations]);

  const amenitiesList = amenitiesData?.length
    ? amenitiesData.reduce((acc, el) => {
        // eslint-disable-next-line no-prototype-builtins
        if (amenityTypes.hasOwnProperty(el)) {
          acc[el] = amenityTypes[el];
        }

        if (allHouseRules.hasOwnProperty(el)) {
          acc[el] = allHouseRules[el];
        }
        return acc;
      }, {})
    : newAmenityTypes;

  const propertiesList = propertyData?.length
    ? propertyData.reduce((acc, el) => {
        // eslint-disable-next-line no-prototype-builtins
        if (propertyTypes.hasOwnProperty(el)) {
          acc[el] = propertyTypes[el];
        }
        return acc;
      }, {})
    : newPropertyTypes;

  const isMobile = useIsMobile();
  const { root, accordion, priceWrapper, priceInput, dropdownRoot, block, total, clearAll, dropdownOption } =
    useStyles();
  const { selectedCurrency } = useContext(CurrencyContext);
  const currencySymbol = getSymbolFromCurrency(selectedCurrency, currencySymbolMap);
  const {
    maxPrice,
    minPrice,
    propertyType,
    tags,
    amenities,
    numberOfBedrooms,
    numberOfBathrooms,
    dispatch,
  } = useContext(SearchValuesContext);
  const [open, setOpen] = useState(false);
  const [isRangeCollapsed, setIsRangeCollapsed] = useState(true);
  const [, isPriceFilterFixEnabled] = useFeatureToggle(PRICE_FILTER_FIX);

  const showPropertiesCount = !isPriceFilterFixEnabled || !(minPrice || maxPrice);
  const translatedPropertyType = getItemTranslation({
    allTranslations: propertyTypes,
    selectedValue: prettifyEnumText(propertyType),
  });

  const handleClosePriceFilter = useCallback(() => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      return;
    }
    setIsRangeCollapsed(true);
  }, [minPrice, maxPrice]);

  const minPriceText = minPrice ? currencySymbol + minPrice : '0';
  const maxPriceText = maxPrice ? currencySymbol + maxPrice : 'max';
  const allText = t('All');
  const propertyTypeText = `${t('Property Type:')} ${propertyType ? translatedPropertyType : allText}`;
  const tagsText = `${categoriesFilterName} ${
    tags.length ? prettifyEnumText(tagsTranslationsMapper?.[tags[0]] || tags[0]) : allText
  }`;
  const firstTranslatedAmenity = getItemTranslation({
    allTranslations: { ...amenityTypes, ...allHouseRules },
    selectedValue: prettifyEnumText(prettifyEnumText(amenities[0])),
  });
  const amenitiesText = `${t('Amenities:')} ${amenities.length ? firstTranslatedAmenity : allText}`;
  const numberOfBedroomsText = `${t('Number of bedrooms:')} ${numberOfBedrooms || allText}`;
  const numberOfBathroomsText = `${t('Number of bathrooms:')} ${numberOfBathrooms || allText}`;

  const handleChangeMinPrice = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_MIN_PRICE, payload: parseInt(value, 10) });
    },
    [dispatch]
  );

  const handleChangeMaxPrice = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_MAX_PRICE, payload: parseInt(value, 10) });
    },
    [dispatch]
  );

  const handleChangePropertyType = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_PROPERTY_TYPE, payload: value });
    },
    [dispatch]
  );

  const handleChangeTags = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_TAGS, payload: value });
    },
    [dispatch]
  );

  const handleChangeAmenities = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_AMENITIES, payload: value });
    },
    [dispatch]
  );

  const handleChangeNumberOfBedrooms = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_NUMBER_OF_BEDROOMS, payload: value });
    },
    [dispatch]
  );

  const handleChangeNumberOfBathrooms = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: SEARCH_VALUES_ACTIONS.SET_NUMBER_OF_BATHROOMS, payload: value });
    },
    [dispatch]
  );

  const clearAllHandler = useCallback(() => {
    dispatch({ type: SEARCH_VALUES_ACTIONS.CLEAR_ALL });
  }, [dispatch]);

  const resetPrice = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_PRICE });
    },
    [dispatch]
  );

  const resetPropertyType = useCallback((e) => {
    e.stopPropagation();
    dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_PROPERTY_TYPE });
  }, [dispatch]);

  const resetTags = useCallback((e) => {
    e.stopPropagation();
    dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_TAGS });
  }, [dispatch]);

  const resetAmenities = useCallback((e) => {
    e.stopPropagation();
    dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_AMENITIES });
  }, [dispatch]);

  const resetNumberOfBedrooms = useCallback((e) => {
    e.stopPropagation();
    dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_NUMBER_OF_BEDROOMS });
  }, [dispatch]);

  const resetNumberOfBathrooms = useCallback((e) => {
    e.stopPropagation();
    dispatch({ type: SEARCH_VALUES_ACTIONS.RESET_NUMBER_OF_BATHROOMS });
  }, [dispatch]);

  const content = (
    <div className={root}>
      <div className={priceWrapper}>
        <div className="d-flex align-items-center">
          <TextField className={cn(block, 'border-0')} color="input" bold onClick={() => setIsRangeCollapsed(false)}>
            <span>
              {t('Price per night:')}
              &nbsp;
            </span>
            {!minPrice && !maxPrice ? <span>{t('All')}</span> : <span>{`${minPriceText}-${maxPriceText}`}</span>}
          </TextField>
          {maxPrice || minPrice ? <Icon icon="close" size={12} onClick={resetPrice} /> : null}
        </div>

        <DropdownMenu isCollapsed={isRangeCollapsed} onDismiss={handleClosePriceFilter}>
          <div className={priceInput}>
            <Input
              value={minPrice}
              onChange={handleChangeMinPrice}
              type="number"
              placeholder="0"
              min={0}
              max={maxPrice}
              prefix={currencySymbol}
              error={maxPrice && minPrice > maxPrice ? t('Wrong min value') : ''}
            />
            <div className="p-2">-</div>
            <Input
              value={maxPrice}
              onChange={handleChangeMaxPrice}
              type="number"
              placeholder={t('Max')}
              min={minPrice}
              prefix={currencySymbol}
              error={maxPrice && maxPrice < minPrice ? t('Wrong max value') : ''}
            />
          </div>
        </DropdownMenu>
      </div>
      {!!(activeTags && tagsData?.length) && (
        <Dropdown
          value={tags}
          className={dropdownRoot}
          variant="multiselect"
          onChange={handleChangeTags}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {tagsText}
                {tags.length > 1 && ` +${tags.length - 1}`}
              </TextField>
              {tags.length ? <Icon icon="close" size={12} onClick={resetTags} /> : null}
            </div>
          }
        >
          {tagsData.map((tag) => (
            <Option value={tag}>{tagsTranslationsMapper?.[tag] || tag}</Option>
          ))}
        </Dropdown>
      )}
      {activePropertyType && (
        <Dropdown
          value={propertyType}
          className={dropdownRoot}
          onChange={handleChangePropertyType}
          variant="single"
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {propertyTypeText}
              </TextField>
              {propertyType && <Icon icon="close" size={12} onClick={resetPropertyType} />}
            </div>
          }
        >
          <Option value={null}>{t('All')}</Option>
          {Object.entries(propertiesList).map(([, { value, label }]) => {
            return <Option value={value}>{label}</Option>;
          })}
        </Dropdown>
      )}
      {activeAmenities && (
        <Dropdown
          value={amenities}
          className={dropdownRoot}
          variant="multiselect"
          onChange={handleChangeAmenities}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {amenitiesText}
                {amenities.length > 1 && ` +${amenities.length - 1}`}
              </TextField>
              {amenities.length ? <Icon icon="close" size={12} onClick={resetAmenities} /> : null}
            </div>
          }
        >
          {Object.entries(amenitiesList).map(([, { value, label }]) => {
            return (
              <Option className={dropdownOption} value={value}>
                {label}
              </Option>
            );
          })}
        </Dropdown>
      )}
      {activeBedroom && (
        <Dropdown
          value={numberOfBedrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBedrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBedroomsText}
              </TextField>
              {numberOfBedrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBedrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBedrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}
      {activeBathroom && (
        <Dropdown
          value={numberOfBathrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBathrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBathroomsText}
              </TextField>
              {numberOfBathrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBathrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBathrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}

      {showPropertiesCount && (
        <TextField className={cn(block, total)} variant="h7" color="secondary">
          {t(
            '{num, plural, =0 {# properties} =1 {# property} few {# properties} many {# properties} other {# properties}} {isTotalPriceEnabled, select, true { found} false {} other {}}',
            {
              num: totalListings || 0,
              isTotalPriceEnabled,
            }
          )}
        </TextField>
      )}

      <TextField className={cn(block, clearAll)} variant="h7" color="link" onClick={clearAllHandler}>
        {t('Clear all')}
      </TextField>
    </div>
  );

  return isMobile ? (
    <Accordion title={t('Filters')} className={accordion} open={open} onChange={() => setOpen(!open)}>
      {content}
    </Accordion>
  ) : (
    content
  );
};

export default SearchFilters;
